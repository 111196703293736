import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Avatar",
  code: "Avatar"
};
export const _frontmatter = {
  "menuLabel": "Avatar",
  "sortOrder": 1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Avatar`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-avatar--athlete-circle" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Avatar } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` component is intended to be used with player or user portraits and logos.  Support for initials coming soon.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The avatar component provides a visually distinguishable thumbnail of a user photo or a visual representation of any other type of content. It displays the image in the specified size, allowing users to perceive and recognize the avatar. The component supports different styles, such as a square portrait or a circular avatar, which further aids in visual perception.
      </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    Users can click on the avatar, triggering the onClick event handler. This allows for associated actions or behaviors, such as displaying more information about the user or navigating to their profile. The component provides feedback by logging a message to the console when clicked, demonstrating its operability.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Avatar component is clear, providing a visual representation of a user or content. Users can easily identify and associate the avatar with the corresponding user or entity. The component supports different sizes and border radius options, allowing for customization based on design requirements. While support for initials is coming soon, the current implementation focuses on displaying an image specified by the src URL.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    Designed to be compatible with assistive technologies, ensuring that screen readers can convey the presence and purpose of the avatar. It functions consistently across different browsers and platforms. The component supports different background colors, including team colors or semantic color tokens, enhancing the robustness of its visual representation.
      </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      